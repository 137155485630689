import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Gatsby plugin ecosystem is there when it's time to stop rewriting the same boilerplate and abstract it into a module to share with others, and stop yourself in the future from rewriting and maintaining the same code across projects.`}</p>
    <p>{`The "plug and play" approach to Gatsby plugins has always fascinated me. I'm a long time user of GraphQL, and it's really nice to be able to drop in a plugin, define a query and have Gatsby call out to an external API, such as a `}<a parentName="p" {...{
        "href": "https://graphcms.com"
      }}>{`headless API`}</a>{`.`}</p>
    <p>{`In this short guide we will explore how I created a Gatsby plugin for the popular `}<a parentName="p" {...{
        "href": "https://statickit.com"
      }}>{`StaticKit`}</a>{` API.`}</p>
    <blockquote>
      <p parentName="blockquote">{`StaticKit is the serverless backend for static sites.`}</p>
    </blockquote>
    <p>{`I recommend signing up if you ever need to deal with forms on a static site. I use StaticKit for a few of my side projects and constantly amazed by the developer experience.`}</p>
    <p>{`If you're working with React, StaticKit expects you to install a `}<a parentName="p" {...{
        "href": "https://statickit.com/docs/react#context"
      }}><inlineCode parentName="a">{`<StaticKitProvider`}</inlineCode></a>{` around your app, and pass in your `}<inlineCode parentName="p">{`Site ID`}</inlineCode>{`. When using Gatsby you'll most likely want to use the `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{` API inside `}<inlineCode parentName="p">{`gatsby-{ssr,browser}.js`}</inlineCode>{` so all pages inside your Gatsby application have access to the StaticKit context.`}</p>
    <p>{`If you don't already have a `}<inlineCode parentName="p">{`gatsby-{ssr,browser}.js`}</inlineCode>{` file, then this creating one to add a provider around your application just might feel like unnecessary boilerplate, especially if you have to repeat this on multiple Gatsby sites. `}<strong parentName="p">{`We can avoid this by creating a Gatsby plugin that handles this for us.`}</strong></p>
    <p>{`The same could be said about creating a `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file but it's probably close to certain that you will be adding more plugins to simplify setup.`}</p>
    <h2>{`How should the plugin work?`}</h2>
    <p>{`Well, we've not yet created anything but let's explore how simple it should be to install the StaticKit provider...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add gatsby-plugin-statickit # npm install gatsby-plugin-statickit
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// In your gatsby-config.js

plugins: [
  {
    resolve: \`gatsby-plugin-statickit\`,
    options: {
      siteId: "...",
    },
  },
];
`}</code></pre>
    <p>{`As you see above all we need to do is pass `}<inlineCode parentName="p">{`siteId`}</inlineCode>{` to the `}<inlineCode parentName="p">{`gatsby-plugin-statickit`}</inlineCode>{` plugin and it will take care of the rest.`}</p>
    <p>{`Really, that's all we need. 😍`}</p>
    <h2>{`Create a plugin`}</h2>
    <p>{`Gatsby has fantastic documentation on `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/creating-plugins/"
      }}>{`creating your own plugin`}</a>{`, so I'd recommend you follow that if you're creating anything more sophisticated than what we are about to in this post.`}</p>
    <p>{`I started by creating a `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/creating-a-local-plugin/"
      }}>{`local plugin`}</a>{` folder named `}<inlineCode parentName="p">{`plugins/gatsby-plugin-statickit`}</inlineCode>{`.`}</p>
    <p>{`Inside here I created the following files:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`gatsby-ssr.js`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`gatsby-browser.js`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`gatsby-node.js`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`index.js`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`package.json`}</inlineCode></li>
    </ul>
    <p>{`Inside `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, give your package a name, description and version.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "gatsby-plugin-your-plugin-name",
  "description": "Give your plugin a description.",
  "version": "1.0.0",
  "main": "index.js",
  "keywords": ["gatsby", "gatsby-plugin", "your-plugin-name"]
}
`}</code></pre>
    <p>{`Since I was going to build a plugin to automatically wrap my application with the `}<inlineCode parentName="p">{`StaticKitProvider`}</inlineCode>{`, it was important that I declared StaticKit and Gatsby both peer dependencies.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd plugins/gatsby-plugin-statickit
yarn add -P @statickit/react gatsby
`}</code></pre>
    <p><em parentName="p">{`Note: `}<inlineCode parentName="em">{`-P`}</inlineCode>{` declares `}<inlineCode parentName="em">{`@statickit/react`}</inlineCode>{` a peer dependency.`}</em></p>
    <p>{`We use peer dependencies here since we don't want to bundle our own version of `}<inlineCode parentName="p">{`@statickit/react`}</inlineCode>{` but instead rely on the end user to install it within their application. This means any time the dependency updates, we won't have to update the plugin code, unless there's a breaking change!`}</p>
    <h2>{`Improve developer experience`}</h2>
    <p>{`Since we're declaring peer dependencies with our plugin, it wouldn't be a great experience for someone installing our plugin to try to use it without the peer dependencies. They'd run into errors and that's not what we want!`}</p>
    <p>{`Inside `}<inlineCode parentName="p">{`gatsby-node.js`}</inlineCode>{` we'll `}<inlineCode parentName="p">{`try`}</inlineCode>{` to resolve the `}<inlineCode parentName="p">{`@statickit/react`}</inlineCode>{` dependency, otherwise we'll `}<inlineCode parentName="p">{`catch`}</inlineCode>{` any errors and throw them to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// gatsby-node.js

try {
  require.resolve("@statickit/react");
} catch (err) {
  throw new Error(\`
  '@statickit/react' is not installed. You must install this to use 'gatsby-plugin-statickit'\`);
}
`}</code></pre>
    <p>{`Now if we try to add our plugin to `}<inlineCode parentName="p">{`gatsby-plugins.js`}</inlineCode>{` and run our application, we'll be warned if we haven't met the peer dependency `}<inlineCode parentName="p">{`@statickit/react`}</inlineCode>{`.`}</p>
    <p>{`I'm not too concerned about checking for `}<inlineCode parentName="p">{`gatsby`}</inlineCode>{` as that would be thrown by your application itself, as there would be no `}<inlineCode parentName="p">{`gatsby`}</inlineCode>{` dependency found to run it!`}</p>
    <h2>{`Plugin code`}</h2>
    <p>{`Gatsby exports a `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{` from it's Node APIs to both `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`gatsby-ssr.js`}</inlineCode>{`.`}</p>
    <p>{`Let's first add the following to `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export { wrapRootElement } from "./gatsby-ssr";
`}</code></pre>
    <p>{`Here we're basically writing clean code, (`}<a parentName="p" {...{
        "href": "https://twitter.com/dan_abramov/status/1130951861123657730?lang=en"
      }}><em parentName="a">{`sorry Dan`}</em></a>{`) so we only have to declare `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{` once.`}</p>
    <p>{`Now inside `}<inlineCode parentName="p">{`gatsby-ssr.js`}</inlineCode>{` we'll perform the magic!`}</p>
    <p>{`Since we're using JSX we need to import React. We'll also import the `}<inlineCode parentName="p">{`StaticKitProvider`}</inlineCode>{` from `}<inlineCode parentName="p">{`@statickit/react`}</inlineCode>{` and use that to wrap the root `}<inlineCode parentName="p">{`element`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react";
import { StaticKitProvider } from "@statickit/react";

export const wrapRootElement = ({ element }, { siteId }) => {
  if (!siteId)
    throw new Error(
      \`'siteId' must be provided when using  'gatsby-plugin-statickit' \`
    );

  return <StaticKitProvider site={siteId}>{element}</StaticKitProvider>;
};
`}</code></pre>
    <p>{`A few things are going on here... The first argument of `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{` is the "Root" React Element built by Gatsby, and, the second argument is our `}<inlineCode parentName="p">{`pluginOptions`}</inlineCode>{` object containing options defined in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`.`}</p>
    <p>{`We need the `}<inlineCode parentName="p">{`siteId`}</inlineCode>{` from the `}<inlineCode parentName="p">{`pluginOptions`}</inlineCode>{` to first check it exists and if it does, add it as a prop to `}<inlineCode parentName="p">{`StaticKitProvider`}</inlineCode>{`.`}</p>
    <p>{`Then all we need to do inside our `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{` function is return the provider the `}<inlineCode parentName="p">{`{element}`}</inlineCode>{` as the children.`}</p>
    <p>{`That's it! Now we have a fully functioning plugin that does what we want.`}</p>
    <p>{`The final code can be found on `}<a parentName="p" {...{
        "href": "https://github.com/notrab/gatsby-plugin-statickit"
      }}>{`GitHub`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/gatsby-plugin-statickit"
      }}>{`NPM`}</a>{`.`}</p>
    <p>{`Huge thanks to `}<a parentName="p" {...{
        "href": "https://www.derrickreimer.com/"
      }}>{`Derrick`}</a>{` for creating an awesome API!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      